<script setup>
import {onMounted, ref} from 'vue';

defineProps({
    name: {
        type: String,
        required: true,
    },
    modelValue: {
        type: String,
        default: null,
    },
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({focus: () => input.value.focus()});
</script>

<template>
    <input
        :id="name"
        ref="input"
        :name="name"
        class="input input-bordered h-[2.5rem] w-full"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)" />
</template>
